
@mixin font-regular-text {
    font-family: 'Ropa Sans';
    font-size: 18px;
    line-height: 1.8;
    font-weight: 400;
    @media (max-width: 768px) {
        line-height: 1.4;
        font-weight: 300;
    }
}

@mixin font-regular-text-tight {
    font-family: 'Ropa Sans';
    font-size: 18px;
    line-height: 1.7;
    font-weight: 400;
    @media (max-width: 768px) {
       
    }
}

@mixin font-small-text {
    font-family: 'Ropa Sans';
    font-size: 16px;
    line-height: 1.8;
    font-weight: 400;
    @media (max-width: 768px) {
       
    }
}

@mixin font-title {
    font-family: 'Ropa Sans';
    font-size: 29px;
    line-height: 35px;
    font-weight: 700;
    text-transform: uppercase; 
    @media (max-width: 768px) {
        font-size: 28px;
        line-height: 35px;
    }
}

@mixin font-subtitle {
    font-family: 'Ropa Sans';
    font-size: 24px;
    line-height: 1.2;
    font-weight: 500;
    text-transform: uppercase; 
    @media (max-width: 768px) {
       
    }
}

@mixin font-teaser-title {
    font-family: 'Ropa Sans';
    font-size: 18px;
    line-height: 18px;
    font-weight: 700;
    text-transform: uppercase; 
    @media (max-width: 768px) {
       
    }
}


@mixin font-button {
    font-family: 'Ropa Sans';
    font-size: 19px;
    line-height: inherit;
    text-transform: uppercase; 
    @media (max-width: 768px) {
       
    }
}

@mixin margins-title {
    @media (max-width: 768px) {
        margin-top: 20px;
        margin-bottom: 14px;
    }
}