@import "variables";
@import "fonts";

@supports not (-ms-high-contrast: none) {
    .amcharts-graph-line {
        -webkit-animation: am-pulsate-line 1s linear infinite;
        animation: am-pulsate-line 1s linear infinite;
    }
    .amcharts-graph-g2 {
        stroke-dasharray: 1000;
        stroke-dashoffset: 1000;
        animation: dash 3s ease forwards !important;
    }
    .amcharts-graph-g3 {
        stroke-dasharray: 1000;
        stroke-dashoffset: 1000;
        animation: dash 3s ease-out forwards !important;
    }
    .amcharts-graph-g4 {
        stroke-dasharray: 1000;
        stroke-dashoffset: 1000;
        animation: dash 3s ease-out forwards !important;
    }
    .amcharts-graph-g5 {
        stroke-dasharray: 1000;
        stroke-dashoffset: 1000;
        animation: dash 3s linear forwards !important;
    }
    .amcharts-graph-line {
        stroke-dasharray: 1000;
        stroke-dashoffset: 1000;
        animation: dash 3s linear forwards;
    }
    @keyframes dash {
        to {
            stroke-dashoffset: 0;
        }
    }
}

.calculator {
    .container {
        margin-bottom: 40px;
        margin-top: 40px;
        @media (max-width: 768px) {
            margin-bottom: 0;
        }
    }
    .input {
        .title {
            background-color: $geyser;
            padding: 37px 0;
            margin-bottom: 14px;
            h2 {
                @include font-title;
                color: $picked-bluewood;
                text-align: center;
                
                text-transform: uppercase;
            }
            h3 {
                color: $picked-bluewood;
                text-align: left;
                @include font-regular-text;
                padding-top: 10px;
                padding-left: 10%;
                padding-right: 10%;
                @media (max-width: 768px) {
                  padding-left: 30px;
                  padding-right: 30px;
                }
            }
        }
        .form {
            .form-inputs {
                &.button-parent {
                    text-align: center;
                }
                display: table;
                width: 100%;
                margin-bottom: 23px;
                &.boxes {
                    margin-top: 40px;
                }
                label {
                    color: $picked-bluewood;
                    font-weight: bold;
                    display: table-cell;
                    vertical-align: middle;
                    text-align: right;
                    padding-right: 31px;
                    width: 50%;
                    @media (max-width: 320px) {
                        width: 100%;
                        display: block;
                    }
                    &.result {
                        display: block;
                        margin-bottom: 12px;
                    }
                }
                .form-input {
                    background: $geyser;
                    color: $picked-bluewood;
                    display: table-cell;
                    width: 100%;
                    border: 1px solid $geyser;
                    padding: 7px 15px;
                    font-weight: bold;
                    outline: none;
                    width: 70%;
                    min-width: 100px;
                    max-width: 150px;
                    //float: right;
                    clear: both;
                    margin-left: 35px;

                    @media (max-width: 430px) {
                        width: 150px;
                    }
                    @media (max-width: 320px) {
                        width: 100%;
                        max-width: 320px;
                        display: block;
                    }
                    
                }
                
                .errorDiv {
                    color: $picked-bluewood;
                }
                button {
                    background: $picked-bluewood;
                    text-align: center;
                    transition: 0.5s ease;
                    padding: 9px 0;
                    width: 45%;
                    @media (max-width: 768px) {
                        width: 100%;
                        //max-width: 380px;
                        float: initial !important;
                        display: table-cell;
                        vertical-align: middle;
                    }
                    text-decoration: none;
                    text-transform: uppercase;
                    margin-left: 0;
                    border: 1px solid $picked-bluewood;
                    @include font-button;
                    cursor: pointer;
                    color: white;
                    outline: none;
                    float: right;
                    &:first-child {
                        float: left;
                        @media (max-width: 768px) {
                            margin-bottom: 16px;
                        }
                    }
                    &:hover,
                    &.active {
                        background: white;
                        outline: none;
                        border: 1px solid $picked-bluewood;
                        color: $picked-bluewood;
                    }
                    @media (max-width: 430px) {
                        width: 100%;
                        padding: 9px 0;
                        margin-bottom: 10px;
                    }
                }
            }
        }
        @media (max-width: 768px) {
            margin-bottom: 0;
            padding: 0 0 0 0;
        }
    }
    .result-area {
        background-color: $geyser;
        padding-bottom: 37px;
        transition: 0.5s ease;
        height: 0;
        padding: 0;
        
        position: relative;
        .show-less{
            display: block;
            position: absolute;
            top: 9px;
            left: 0;
            right: 0;
            text-align: center;
            cursor: pointer;
            a{
                border: 1px solid #d2dbde;
                border-radius: 30px;
                height: 25px;
                width: 40px;
                display: inline-block;
                font-size: 30px;
                line-height: 25px;
                background-color: white;
                color: #2d475b;
            }
        }
        &.show{
            height: 100%;

            @media (max-width: 768px) {
                margin-top: 40px;
            }
        }
        .title {
            padding: 37px 0;
            margin: 0 100px 40px 100px;
            border-bottom: 3px solid white;
            @media (max-width: 768px) {
                margin: 0 20px 0 20px;
                padding-bottom: 0;
                border-bottom: 0;
            }
            display: none;
            transition: opacity 1s ease-out;
            opacity: 0;
            &.show {
                display: block;
                opacity: 1;
            }
            h2 {
                text-align: center;
                font-weight: bold;
                .text {
                    color: white;
                    padding: 0 7px;
                }
                .value {
                    color: $picked-bluewood;
                }
            }
        }
        table {
            width: 100%;
            margin-bottom: 40px; //display: none;
            @media (max-width: 768px) {
                margin-bottom: 10px;
            }
            transition: 0.5s ease;
            opacity: 0;
            &.show {
                // display: block;
                opacity: 1;
            }
            thead {
                margin-bottom: 20px;
                td {
                    @include font-regular-text;
                    font-weight: bold;
                    padding-bottom: 20px;
                    padding-right: 10px;
                    color: $picked-bluewood;
                    &:not(:first-child) {
                        text-align: right;
                        @media (max-width: 768px) {
                            padding-left: 50px;
                            text-align: left;
                        }
                    }
                }
               @media (max-width: 768px) {
                    display: none;
               }
            }
            tbody {
                color: $picked-bluewood;
                tr {
                    td {
                        padding: 20px 0;
                        @include font-regular-text;
                        &:not(:first-child) {
                            border-top: 1px solid $picked-bluewood;
                            padding-right: 10px;
                            text-align: right;
                            @media (max-width: 768px) {
                                border-top: none;
                                padding: 0 30px;
                            }
                        }
                        &:first-child {
                            @media (max-width: 768px) {
                                padding: 20px 30px 0 30px;
                            }
                        }
                        &:last-child {
                            @media (max-width: 768px) {
                                padding-bottom: 10px;
                            }
                        }
                       @media (max-width: 768px) {
                            display: block;
                            text-align: right;
                            position: relative;
                            
                            &:before {
                                position: absolute;
                                left: 30px;
                                width: 41%;
                                line-height: 1;
                                padding-right: 10px;
                                text-align: left;
                                content: attr(data-title);
                            }
                        }
                    }
                    &:first-child {
                        td {
                            
                            &:not(:first-child) {
                                background: $porcelian;
                                font-weight: bold;
                                padding-right: 10px;

                                @media (max-width: 768px) {
                                    font-weight: normal;
                                    padding-right: 30px;
                                }
                            }

                            @media (max-width: 768px) {
                                background: $porcelian;
                            }

                        }
                    }
                    &:last-child {
                        td {
                            &:not(:first-child) {
                                border-bottom: 1px solid $picked-bluewood;
                               @media (max-width: 768px) {
                                    border-bottom: none;
                               }
                            }
                        }
                    }
                    @media (max-width: 768px) {
                        border-bottom: 1px solid $picked-bluewood;
                   }
                }
            }  
            @media (max-width: 768px) {
              //   display: block;
                 overflow-x: auto;
             }
        }
    }
    .text-area {
      
        margin-bottom: 10px;
        p {
            color: $picked-bluewood;
            @include font-regular-text;
            &.small-margin {
                margin-bottom: 5px;
            }
            

            &.text-area-titl{
                font-weight: bold
            }
        }
        
        @media (max-width: 768px) {
            p.details-d {
                display: none;
            }
        }
        
        p.details {
            @media (max-width: 768px) {
                display: block;
            }
            @media (min-width: 769px) {
                display: none;
            }
            @media (max-width: 768px) {
                &.expanded {
                    &::after {
                        content: "_";
                        color: transparent;
                        pointer-events: all;
                        background-image: url(../assets/images/triangle-rotated.svg);
                        background-size: 6px 12px;
                        background-repeat: no-repeat;
                        background-position-x: 3px;
                        background-position-y: 1px;
                    }
                }
                &.collapsed {
                    &::after {
                        content: "_";
                        color: transparent;
                        pointer-events: all;
                        background-image: url(../assets/images/triangle.svg);
                        background-size: 6px 12px;
                        background-repeat: no-repeat;
                        background-position-x: 3px;
                        background-position-y: 1px;
                    }
                }
            }
        }
        .label {
            font-weight: bold;
        }
        @media (max-width: 768px) {
            margin-bottom: 30px;
        }
    }
    .buttons {
        text-align: center; // padding-bottom: 37px;
        .child {
            background: $picked-bluewood;
            text-align: center;
            transition: 0.5s ease;
            padding: 9px 0;
            max-width: 270px;
            width: 100%;
            text-decoration: none;
            text-transform: uppercase;
            margin-left: 0;
            border: 1px solid $picked-bluewood;
            @include font-button;
            cursor: pointer;
            color: white;
            outline: none;
            float: right;
            &:first-child {
                float: left;
            }
            &:hover,
            &.active {
                background: white;
                outline: none;
                border: 1px solid $picked-bluewood;
                color: $picked-bluewood;
            }
            @media (max-width: 993px) {
                float: none !important;
                margin: 0 auto;
                &:first-child {
                    margin-bottom: 10px;
                }
            }
        }
    }
    .mobile {
        display: none;
        @media (max-width: 768px) {
            display: block;
        }
    }

    .accordion {
        cursor: pointer;
       // padding: 18px 18px 0 18px;
        width: 100%;
        text-align: left;
        border: none;
        outline: none;
        transition: 0.4s;
    }
    
    /* Style the accordion panel. Note: hidden by default */
    
    .panel {
       // background-color: white;
        max-height: 0;
        overflow: hidden;
        transition: max-height 0.2s ease-out;
    }
    
    .desktop {
        @media (max-width: 768px) {
            display: none !important;
        }
    }
}

.no-padding {
    padding: 0;
}

.result-container {
    width: 100%;
    padding-left: 15px;
    padding-top: 20px;
    padding-right: 15px;
    margin-left: auto;
    margin-right: auto;
    overflow: hidden;
    margin-bottom: 30px;
    @media (max-width: 768px) {
        margin-bottom: 0px;
        padding-left: 0;
        padding-right: 0;
    }
    > div {
        @media (max-width: 768px) {
            padding-left: 30px;
            padding-right: 30px;
        }
    }
    @media (min-width: 576px) {
        max-width: 540px;
    }
    @media (min-width: 769px) {
        max-width: 720px;
    }
    @media (min-width: 993px) {
        max-width: 960px;
    }
    @media (min-width: 1201px) {
        max-width: 1140px;
    }

}


    #starting-balance{
    -moz-appearance:textfield;
    }


    #starting-balance::-webkit-outer-spin-button,
    #starting-balance::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */

    -webkit-appearance: none;
    -moz-appearance:textfield;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
    
}